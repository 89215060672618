export default class Helper {
	static titleCase(text) {
		return text.replace(/([a-z])([A-Z])/g, '$1 $2');
	}
	static snakeToTitleCase(text) {
		let result = text.split("_");

		result = result.map(t => t[0].toUpperCase().concat(t.substring(1, t.length))).join(" ");

		return result;
	}
	static displayHour(hour) {
		return hour < 10 ? `0${hour}` : hour == 60 ? '00' : hour  
	}
	static displayMins(min) {
		return min < 10 ? `0${min}` : min == 60 ? '00' : min   
	}
	static displaySec(sec) {
		return sec < 10 ? `0${sec}` : sec == 60 ? '00' : sec  
	}
}